// Variable overrides
$primary :#de148c;
$dark :#878787;
$grey: #333332;
$hoverColor: #ebebeb;
$sidebar-width: 250px !default;
$sidebar-font-size:12px !default;
$sidebar-light-nav-link-hover-bg:          $hoverColor !default;
$sidebar-light-nav-link-active-color: $primary !default;
$sidebar-light-nav-link-color: $grey !default;
$sidebar-light-nav-link-hover-color:          $grey !default;
$sidebar-light-nav-link-hover-icon-color:          $grey !default;
$sidebar-light-nav-group-indicator-color:        $primary !default;
$btn-font-weight:500 !default;
$light-color: #FCA5A5; 
// $gray-100: #f5e9f5;
// $gray-200: #dbd0db;
// $gray-300: #d1b4ce;
// $gray-400: #b39ab0;
// $gray-500: #9e869b;
// $gray-600: #7d6a7b;
// $gray-700: #5e4f5d;
// $gray-800: #473b47;
// $gray-900: #2b242b;